<template>
  <div class="login">
    <Header title="登录" />
    <div class="content">
      <div class="info">
        <div class="dv-img">
          <img class="img" src="@/assets/logo.png" />
        </div>

        <div class="title">欢迎登陆·山海景藏</div>
        <!-- <div class="welcome">Welcome</div> -->
        <div class="login-button" @click="togo('./logind')">
          手机验证登录
        </div>
        <div class="bottom">
            <van-checkbox v-model="checked" class="checkbox"></van-checkbox>
          <div class="xy">
            已阅读并同意<span style="color: white"><a href="javascript:void(0)" @click="clickService()">《用户服务协议》</a></span
            ><span style="color: white"><a href="javascript:void(0)" @click="clickPolicy()">《隐私权政策》</a></span>
          </div>
        </div>
        <a class="bah" href="https://beian.miit.gov.cn/" target="_blank">备案号:赣ICP备2020012555号-4</a>
      </div>
    </div>
    <license-policy v-if="showPolicyFlag" @closePolicy="closePolicy"/>
    <license-service v-if="showServiceFlag" @closeService="closeService"/>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { useStore } from "vuex";
import { Toast } from "vant";
import Header from "../../components/Header2.vue";
import LicensePolicy from '@/pages/login/license-policy'
import LicenseService from '@/pages/login/license-service'
import { useRouter } from "vue-router";
import { ref } from "vue";
export default {
  components: {
    Header,
    LicensePolicy,
    LicenseService
  },
  setup() {
    const router = useRouter();
    const checked = ref(false);
    const showPolicyFlag = ref(false)
    const showServiceFlag = ref(false)
    //路由跳转
    const togo = (path) => {
      if(!checked.value) {
        Toast("请先同意用户服务协议和用户政策");
        return
      }
      router.push(path);
    };
    const clickService=  () => {
      showServiceFlag.value = true
    }
    const clickPolicy = () => {
      showPolicyFlag.value = true
    }

    return {
      togo,
      checked,
      showPolicyFlag,
      showServiceFlag,
      clickService,
      clickPolicy,
      
    };
  },
  methods: {
    closePolicy() {
      this.showPolicyFlag = false
    },
    closeService() {
      this.showServiceFlag = false
    },
 }
};

</script>


<style lang='less' scoped>
/deep/ .van-checkbox__icon .van-icon{
  width: 1em;
  height: 1em;
}
.login {
  background: rgba(33, 30, 32, 1);
  position: fixed;
  overflow: auto;
  width: 100%;
  height: 100%;
  .content {
    background: rgba(33, 30, 32, 1);
    flex: 1;
    overflow-y: auto;
    .info {
      display: flex;
      flex-direction: column;

      .dv-img {
        .img {
          width: 71px;
          height: 71px;
          margin: auto;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 10;
          right: 0;
          margin-top: 86px;
        }
      }

      .title {
        text-align: center;
        color: rgba(196, 196, 196, 1);
        letter-spacing: 6px;
        font-size: 12px;
        margin-top: 162px;
      }
      .welcome {
        text-align: center;
        color: rgba(196, 196, 196, 1);
        font-size: 12px;
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
      }
      .login-button {
        height: 50px;
        line-height: 50px;
        text-align: center;
        background: rgba(50, 50, 50, 1);
        border-radius: 10px;
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin-left: 20px;
        margin-right: 20px;
      }
      .bottom {
        text-align: center;
        color: rgba(196, 196, 196, 1);
        font-size: 12px;
        position: absolute;
        top: 10;
        left: 0;
        bottom: 0;
        right: 0;
        margin-left: 30px;
        margin-bottom: 200px;
        display: flex;
        flex-flow: row;
        justify-content: flex-start;

        .xy {
          margin-left: 10px;
          a {
            color: white;
          }
        }
      }
    }
  }
   .bah {
        text-align: center;
        color: rgba(196, 196, 196, 1);
        font-size: 12px;
        position: absolute;
        top: 10;
        left: 0;
        bottom: 0;
        right: 0;

        margin-bottom: 100px;
        display: flex;
        flex-flow: row;
        justify-content: center;

        .xy {
          margin-left: 10px;
          a {
            color: white;
          }
        }
      }
}
</style>
