<template>
  <div>
    <van-popup
      v-model:show="licensePolicyShow"
      position="center"
      @click-overlay="closePopup"
      :overlay-style="{ opacity: 0.2 }"
      round="true"
      color="rgba(33, 30, 32, 1)"
    >
      <div class="content">
        <textarea>
                                                                  山海景藏数字藏品平台用户服务协议
                                                                                                        20220228版本


《山海景藏数字藏品平台用户服务协议》(以下简称 “本协议”）是由您与成都美家直选信息技术有限公司 （作为开发者及运营方）（以下统称 “我们”）就我们向您 提供数字藏品平台服务（以下简称 “本服务”）所订立的有效合约。请您先仔细阅读本协议内容，尤其是字体加粗部分。如您对本协议内容或页面提示信息有任何疑问的，请勿进行下一步操作。
【特别提醒】
您需审慎阅读、充分理解本协议的各个条款，尤其是以粗体并下划线标示的条款，包括第三条的数 字藏品购买和转赠规则、第四条的平台服务说明及使用规范、第五条用户违约及处理措施、第八条 免责事项、第十条的法律适用和争议解决以及附件一的《违规用户处罚规则》等可能影响您权利和 责任的相应条款。
您确认，一旦您以在线点击确认或其他方式签署本协议，即视为您已阅读并同意本协议的所有条款 ，知晓并理解本协议所有条款的含义且愿意承担相应的法律后果。本协议视为已于您在线点击确认 或以其他方式签署本协议或您完成注册程序之日有效签署，对各方成立并生效。
我们关注且重视未成年人权益的保障。若您未满十八周岁，请在监护人的陪同下阅读和判断是否同 意本协议。您点击确认或其他类似按钮并继续使用本服务即视为您已经取得监护人的必要同意。
一、   定义
1.1 数字藏品平台或平台：指我们开发运营的数字藏品的发行、信息展示和交易的平台，包括山海景藏APP、山海景藏小程序及其后续我们不时开发的其他App、小程序或网页等应用软件。
1.2 数字藏品：指使用新版链区块链技术在山海景藏平台中进行唯一标识的经数字化的特定虚拟作品、艺术品和商品 ，包括但不限于数字图片、音乐、视频、3D模型等各种形式的虚拟数字藏品。
1.3 发行方：指在平台发行数字藏品并向您销售和提供数字藏品的实体或个人。
1.4 服务：指我们以及我们的关联方和合作方基于互联网和区块链，以包含数字藏品平台在内的各种 形态（包括未来技术发展出现的新的服务形态）向您提供的各项服务，包括但不限于以下服务     ：(i)数字藏品的展示、浏览、购买、转赠、交易争议处理、订单管理等信息技术服务；(ii)数字藏品 的应用场景相关的应用服务；以及(iii)将数字藏品在山海景藏进行存证、管理、簿记及交收等存管服务。
1.5平台规则：指已在或后续将在平台发布的各类规则、规范、公告、说明、解读、声明、指引或其 他类似内容，具体以平台实际展示为准。
1.6应用场景：指平台之外的提供数字藏品信息展示、交互和其他应用活动的各类App、小程序和网 页等应用软件。

二、   账户注册、管理和注销
2.1 在您通过平台注册或使用本服务之前，您应确保您具备与使用本服务及购买、转赠数字藏品等相 适应的民事行为能力，并有能力承担对应的法律后果和法律责任。如果您是未成年人，您在平台购

买、转赠数字藏品或进行其他行为，均视为您已经获得了您监护人的同意。
2.2 账户注册：您应当按照平台页面的提示直接提供您的手机号、或者通过支付宝账户并绑定手机号 的形式，开立和注册您的平台账户。当您成功完成注册程序后，您即取得平台账户并成为平台用户 。在平台一个手机号仅能注册和绑定一个账户。为保障您的账户安全，您同意按照平台的要求及相 关法律法规规定完成账户的实名认证。
2.3 账户登入：您可通过您的账户名称（即手机号）和手机验证码、支付宝账号或其他平台允许的方 式登录平台并使用本服务。

2.4 账户管理：您的账户仅限于您本人使用。除法律法规或平台规则另有规定，您不得将您的账户以 任何方式进行转让、出借或出售。请您务必妥善保管账户登录名、短信校验码等信息。在您的账户 登录状态下进行的所有操作、发出的所有指令均视为您本人做出。如果因您自身的原因造成您的账 户被冒用、盗用或非法使用的，我们不承担由此造成的风险和您的损失。
2.5 账户的限权: 我们有权依据本协议约定或者法律法规的规定对您的账户进行限权或关闭。在限权 或关闭账户之前，我们将通知您对账户内的数字藏品进行处置。

2.6 账户注销: 您可以申请注销您的账户。在申请注销账户之前，您需对账户内的数字藏品进行处置 ，包括在符合法律法规和交易平台规则的情况下将数字藏品转移至另一账户。如果在您发起注销账 户申请并授权我们处置账户之日起六十日之内未能主动对账户内的数字藏品进行处置的，视为您同 意自愿放弃对上述数字藏品的包括所有权在内的一切权益。我们将在六十日届满之日对数字藏品进 行处置。如您在发起注销账户申请之日起的六十日之内再次登陆账户的，视为您撤回上述注销账户 的申请。请您注意账户一旦成功注销将不可恢复，即使您在注销后以同一手机号或同一支付宝账号 再次在平台注册账户，该账户也将视为新的用户账号，您在原注销账户内的数字藏品将无法被找回 。因此请您谨慎操作。

三、  数字藏品购买和转赠规则
3.1  数字藏品的购买：
3.1.1   您通过数字藏品平台购买或兑换数字藏品，应当是已年满14周岁的中国大陆居民，并且已经 通过平台的实名认证流程。

3.2  数字藏品的转赠：
3.2.1   在平台规则或平台功能允许的情况下，您可以将所持有的数字藏品转赠给平台的其他用户。 您理解并同意，一旦发起转赠操作将无法撤销，且转赠成功后，与数字藏品相关的权利将同步且毫 无保留地转移至受赠人 。
3.2.2   您通过平台转赠数字藏品，应当满足以下必要的条件：
(1) 您与受赠人均为中国大陆居民身份且已年满14周岁；
(2) 您与受赠人均已通过平台的实名认证；
(3) 如果您是从数字藏品发行方处通过购买或兑换方式获得数字藏品的，您至少需要持有该数字藏品 达到90天后才能转赠；如果您是从转赠人处通过受赠方式获得数字藏品的，您应自受赠时起持有该 数字藏品满90天后才能转赠。（具体时限可能不定期调整，我们将在平台进行及时更新和公告，请您 留意平台的公告信息）。
3.3  您理解并确认，我们有权依据国家法律法规、监管政策、平台规则或业务需要对上述购买和转 赠条件进行补充或修改。您与受赠人一旦点击页面“同意”按钮确认本协议或某一平台规则，或者 发起购买或转赠申请，即表示您与受赠人承诺符合购买/转赠条件并同意受到本协议及平台规则的约 束。

四、  平台服务说明及使用规范
4.1 平台服务说明：
4.1.1 您有权在平台上享受数字藏品的浏览、购买、分享、转赠、争议处理、订单管理以及数字藏品 的访问、欣赏、炫耀、信息查看等信息技术服务，部分数字藏品还可通过平台合作的应用场景进行 使用，具体服务内容和功能以平台页面向您实际展示的为准。
4.1.2 您可以通过平台浏览不同发行方提供的数字藏品及其相关信息。当您下单购买前，请您务必仔 细确认所购买数字藏品的名称、介绍、发行方、价格、数量及/或其他限制性要求等重要事项，并对 是否购买数字藏品做出独立决策和判断。一旦您提交订单，请在规定时限内完成支付。您支付成功 后即视为您与发行方之间就已支付价款部分的订单建立了合同关系。特别提醒本平台售出的数字藏 品均为虚拟数字商品，而非实物，且因为区块链技术的特性，本平台售出的数字藏品不支持七天无 理由退换。
4.1.3 基于区块链技术的特性，数字藏品具有加密和不可篡改的特点。一旦上链，数字藏品元数据中 的作者、创作时间和上链时间、购买者等信息，在链上无法篡改。您购买相关数字藏品后，您的购 买者信息将记录在数字藏品的元数据中，作为您持有该数字藏品的权利凭证。
4.1.4 数字藏品的图文展示、介绍、创作者等相关信息均由发行方提供，所有数字藏品的内容和版权 由发行方单独承担责任。您应当对通过平台接触到的内容及其准确性、完整性和有效性自行审慎判 断并承担由此引起的相应风险。除我们存在故意或重大过失外，我们不对您因前述风险而导致的损 失或损害承担责任。
4.1.5 为保障您和发行方的交易和资金安全，您同意以支付宝或平台认可的其他支付方式作为您购买 数字藏品的支付工具。您同时授权支付宝或平台认可的其他支付方式的提供商可以按照发行方和/或 我们向您发出的指令为您提供资金结算和支付账户管理等服务。

4.1.6 您理解并同意，数字藏品的版权由发行方或原作创作者拥有。除另行取得版权权利人书面同意 外，您不得将数字藏品用于任何商业用途。除平台规则或平台功能允许外，您获得的数字藏品应仅 用于自身收藏、学习、研究、欣赏和展示目的，不得用于任何其他目的。
4.1.7 我们严格禁止您利用数字藏品进行售卖、炒作、场外交易、欺诈或以任何其他非法方式进行使 用。我们保留随时根据业务发展和法律法规政策变化进行调整的权利。您认可并同意，为了保障交 易安全、有序、合法以及履行我们反洗钱的义务，我们有权对您的区块链地址进行安全查询。如发 现存在售卖、炒作、欺诈等异常行为，或您存在违反法律法规或本协议约定等情形，我们有权对您 采取本协议第五条约定的必要措施。
4.2 平台服务使用规范：
4.2.1   您承诺将始终遵守本协议以及与数字藏品相关的交易订单、平台规则以及我们不时更新且在 平台公示的平台规则，包括但不限于本协议附件一规定的《违规用户处罚规则》等，并遵守支付宝 或其他第三方服务提供商（如有）与您达成的相关协议或规则。
4.2.2   您声明并保证，您对您在使用本服务及购买数字藏品的过程中所提供、发布的任何信息拥有 完整、合法、有效的权利，并且符合相关法律法规及本协议的要求，不存在任何违反法律法规、国 家政策以及其他违背社会公共利益、公共道德或妨害平台正常运行、侵犯他人合法权益的信息或行 为。您同意我们有权对您提供或发布的信息内容进行审查，如发现违规或不适宜在平台展示的，我 们有权进行删除或屏蔽处理。
4.2.3   您应对通过本服务了解、接收或接触到的包括但不限于发行方或其他用户在内的任何个人信 息和资料予以充分尊重。您不得擅自搜集、复制、存储、传播或以其他任何商业目的使用前述个人

信息和资料，不得擅自使用任何第三方软件、插件、外挂、系统等查看、获取本服务中所包含的我 们及我们的关联方、合作方或发行方、其他用户的任何相关信息、数据等内容 。
4.2.4   您理解，我们提供本服务有赖于平台系统的准确运行及操作。若出现系统差错、故障或其他 原因引发了展示错误、您获得不当获利等情形的，您同意我们可以自行或通过我们的关联方、合作 方采取更正差错、暂停服务、划扣款项等适当纠正措施 。
4.2.5   您在使用本服务或数字藏品交易过程中与发行方、应用场景提供方或其他用户等第三方产生 的任何争议，应由您自行和发行方或其他第三方协商解决，我们将为您提供必要的支持和协助。如 果您申请我们为您提供争议调处服务，或您向我们进行任何投诉、举报或通知，您需要向我们提供 相应的凭证以初步证明您的主张。
五、  用户违约及处理措施
5.1 您理解并同意，为了维护交易秩序和交易安全，如我们发现或合理判定您存在违法违规或违反本 协议约定情形的，我们有权采取救济措施，包括但不限于：

(1) 要求您限期纠正违规或违约行为；
(2) 主动执行关闭相关交易订单；
(3) 屏蔽或限制您访问、使用数字藏品或限制您持有的数字藏品的某些功能；
(4) 对违法违规信息进行删除或屏蔽；
(5) 暂停或终止向您提供部分或全部服务；
(6) 采取本协议附件一《违规用户处罚规则》规定的相关处罚措施；以及
(7) 其他合理、合法的措施。
5.2 如您的行为使我们及/或我们的关联方和合作方等遭受损失的，我们有权要求您赔偿我们的全部 损失，包括商誉、支付的赔偿金、律师费、诉讼费等。

六、   软件许可
6.1 我们授予您一项仅限于个人非商业目的、非排他性、可撤销、不可转让、不可分许可的有限的普 通许可，您可以在本协议有效期内，按照本协议及平台规则限定的范围和方式使用山海景藏网页、小程序、APP手机客 户端软件（“本软件”）及相关平台服务。未经我们另行书面许可，你不得为商业目的下载、安装 、登录和使用本软件。本协议未明示授权的其他一切权利仍由我们或相关权利方保留。
6.2 我们会不断丰富您使用本软件的终端、内容和形式。您理解由于软件适配及终端限制，我们可能 开发不同的软件版本。您应当选择与您的终端、系统等相匹配的软件版本，否则由此造成的本软件 无法正常运行或您的终端、系统及其软硬件功能损害的，我们不承担责任。
6.3 为了改善用户体验、完善服务内容、以及提高软件安全性等目的，我们有权对本软件及相关平台 服务、功能、界面等进行更新、修改，包括开发新的功能、删除旧功能等，该等变更不视为我们对 本协议的履行存在任何瑕疵，您同意不会因此追究我们的责任。您理解在本软件版本更新后，我们 不保证旧版本的软件及其服务、功能等可以继续正常使用，请您随时关注并将本软件更新至最新版 本。
6.4 您应当从本软件的官方网站、官方应用分发平台及/或我们授权的其他第三方网站、应用分发平 台等渠道获取本软件。如果您从非官方或未经我们授权的第三方获取本软件或与本软件名称相同的 安装程序，我们无法保证该软件能够正常使用和运行，由此产生的任何损害或损失将由您自行承担。
6.5 您仅可通过本人人工操作的方式使用本软件及相关平台服务，不得自行或允许第三方通过非官方 程序、机器、脚本、爬虫或其他自动化的方式使用本软件及相关平台服务或获取该等软件和服务数据。
七、  用户信息的保护及授权
我们非常重视对您个人信息的保护，您同意我们依据本协议及《平台隐私权政策》向您收集为您提 供本服务所需要的信息并进行合理使用、处理、存储和共享。我们将遵循合法、正当、必要和诚信 、公开、透明的原则，依据适用法律法规及平台隐私权政策的要求严格保护您的个人信息，请您在 使用本服务前仔细阅读《平台隐私权政策》，如有任何疑问请随时联系我们。
八、   免责事项
8.1 我们将努力保障您的合法权益。请您理解，在您购买数字藏品或使用本服务的过程中，可能会遇 到不可抗力等风险因素。如出现下列任一情况致使我们无法履行本协议项下约定或您无法完成数字 藏品交易的，我们将努力在第一时间与相关单位配合并及时进行修复，但是由此给您或第三方造成 的损失，我们不承担责任：
(1) 在与本服务相关的系统停机维护或升级期间；
(2) 因自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、法律法规政策变化和 政府行为等不可抗力原因；
(3) 您操作不当或通过非我们授权或认可的方式使用本服务；
(4) 因病毒、木马、恶意程序攻击、网络拥堵、系统不稳定、系统或设备故障、通讯故障、电力故障 、电信设备故障、第三方服务瑕疵等原因。

8.2 您理解并同意我们仅向您提供与数字藏品交易相关的信息技术服务及其他辅助服务。除法律法规 另有明确规定或本协议另有约定外，我们不成为您与发行方或第三方之间任何交易或业务的参与方 ，不对所涉数字藏品及/或第三方服务的质量、安全性及其合法性、真实性、准确性作出任何明示或 暗示的担保 。
8.3 您理解并确认，我们仅以“现状”向您提供本服务。您在确认接受本协议或使用本服务前已充分 了解数字藏品平台的功能和服务特性并同意我们无须就软件瑕疵、功能不足或任何需改进事项对您 承担任何除法律有明确规定外的责任。
8.4 您理解并同意，鉴于网络服务的特殊性，我们对本服务的持续提供可能受到多种因素的影响，如 出现技术升级、服务体系升级、或因经营策略调整或配合国家重大技术、法规政策等变化。我们可 能随时终止、中断提供服务或变更所提供服务的形式、规格或其他方面，而您不会因此追究我们的 责任。
8.5 为便于向您提供服务，我们可能在平台设置相应的外部链接。除法律另有明确规定外我们不对外 部链接的准确性、有效性、安全性和完整性作出任何承诺或保证。同时，对于该等外部链接指向的 不由我们实际控制的任何网页或客户端上的内容，您同意我们将不承担相关责任 。
8.6 本协议项下涉及的所有数字藏品，由发行方保证对数字藏品享有作为权利人完整、合法、有效的 权利或已取得权利人的合法授权，保证有权开展数字藏品生成、发行、转赠等场景的应用活动，并 保证其开展数字藏品业务不违反任何法律法规或侵犯第三方的合法权益。如因数字藏品发行方或原 创作者侵权、发布危害国家利益、违反公序良俗以及其他可能产生严重负面舆情的言论或行为，或 者数字藏品存在权利瑕疵、内容违法违规、或其他可能产生严重负面舆情等情形，导致数字藏品被 屏蔽或限制使用的，您同意我们无需承担相关责任，您可以自行联系发行方解决，或者您也可以联 系客服协调处理 。
8.7 新版链仅就数字藏品在山海景藏上的信息存证和唯一标识提供区块链技术服务。
九、   知识产权

9.1 平台上的所有内容，包括文字、图片、档案、资讯、资料、平台架构、平台画面的安排、网页设 计等，均由我们或其他权利人依法拥有其知识产权，包括但不限于商标权、专利权、著作权、商业 秘密等。
9.2 非经我们或其他权利人书面同意，您不得擅自使用、修改、反向编译、复制、公开传播、改变、 散布、发行或公开发表、转让、许可、商业利用平台的相关软件、内容以及其他我们或权利人享有 的知识产权，否则我们有权立即终止本协议，您需对您的违约或侵权行为给我们或其他权利人造成 的损失承担相应赔偿责任。
十、  其他
10.1    为进一步改善用户体验，我们将联合我们的关联方和/或合作方持续更新服务，包括但不限 于版本升级、功能升级、技术升级、新增、更新或调整服务内容和功能，本协议可能会随之更新。 我们也会根据国家法律法规的变化要求，更新本协议的相关条款。我们会以公告、客户端信息推送 、发送系统消息等通知方式（统称“通知”）就协议的更新向您进行提示。除通知中另有说明外  ，更新的内容将于协议更新公告公布的生效之日起生效。若您不接受变更后的服务内容或功能，或 不同意更新后的协议内容，您需立即停止使用相关服务。若您继续使用本协议项下相关服务的，即 视为您已同意我们作出的变更，并自愿接受变更后的协议内容约束；双方协商一致，也可另行变更 相关服务和对应条款内容。
10.2    您理解并同意，出于本服务的业务特性，未经我们书面同意，您不得向第三方转让本协议 项下的权利和义务。您进一步同意，在不对您使用本服务造成不利影响的前提下，我们可将在本协 议项下的权利和义务全部或部分转让给我们的关联方而无需另行通知或征得您的同意。
10.3    我们在平台页面展示的最新服务说明、平台规则等构成本协议不可分割的一部分。如果条 款之间存在不一致之处，以（1）服务说明、平台规则；（2）本协议的先后顺序进行适用。

10.4    本协议的部分条款无论因何种原因部分无效或不可执行，其余条款仍有效并对双方仍具有 约束力。

10.5    本协议于浙江省杭州市西湖区签订。本协议之效力、解释、变更、执行与争议解决均适用 中华人民共和国法律。因本协议产生的争议，均应提交本协议签订地杭州市西湖区人民法院管辖。

附件一《违规用户处罚规则》
为维护数字藏品平台的正常运营，保障各方的合法权益，严格防范和打击任何形式的转售、炒作数 字藏品的违法违规行为，依据《山海景藏数字藏品平台用户服务协议》制定本规则。
1. 如果我们收到投诉或举报，或通过其他途径，经过合理判断认定您从事了以下违法、违规或违约 行为的，我们有权根据您的具体行为和情节实施下述处罚措施，并向您发送处罚通知：
  行为                          具体情形
转售数字藏品           任何形式有偿转售数字藏品的行为，包括但不限于在任何平台、网站、社交媒体等发 
违规使用数字藏品       违规使用数字藏品，包括但不限于超过发行方的授权范围使用数字藏品。
发布违规或不当信息     发布违规或不当信息，损害山海景藏数字藏品平台或他人合法权益。
违法犯罪行为           构成违法犯罪的，包括但不限于欺诈、洗钱、非法经营等。
其他违约行为           其他违反《山海景藏数字藏品平台用户服务协议》、《数字藏品购买和转赠须知》、平
2. 如您同时涉及前述多项违反情形的，我们有权分别采取多项对应的处罚措施，或有权按照从重处 罚原则对您的相关行为予以处罚 。
3. 针对他人对您的投诉和举报，或我们对您的处罚措施，您可以提交相关证明材料进行申诉，我们将依据事实进行处理。
4. 您理解并确认，我们有权依据国家法律法规、监管政策、或风险防范的需要对上述处罚规则进行 修改、调整、补充和公示。
     </textarea
        >
      </div>
    </van-popup>
  </div>
</template>

<script>
import { ref } from "vue";
import { Toast } from "vant";
import { reactive, toRefs } from "vue";
export default {
  setup() {
    const licensePolicyShow = ref(true);
    return {
      licensePolicyShow,
    };
  },
  methods: {
    closePopup() {
      this.$emit("closeService");
    },
  },
  created() {},
};
</script>

<style lang="less" scoped>
.content {
  width: 350px;
  height: 550px;
  background: rgba(50, 50, 50, 1);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  textarea {
    margin: 10px;
    width: 325px;
    height: 520px;
    font-size: 8px;
    background: rgba(50, 50, 50, 1);
    color: white;
  }
}
</style>
